import React, {useState, useEffect} from 'react';
import Nav from '../../components/Nav/Nav';
import Footer from '../../components/Footer/Footer/Footer';
import ProductsInner from './productsInner';
//import ProductsInnerGt from './productsInnerGt';

const Products = ( {dimensions} ) => {
  return (
    <>
      <Nav />
      <ProductsInner dimensions={dimensions} />
      <Footer />
    </>
  );
};

export default Products;
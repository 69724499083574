import React, {useState, useEffect} from "react";
import './MobileButton.scss';

export default function MobileButton(
    {
        dimensions, 
        slideshowWidthGt, 
        slideshowWidthLt,
        slideshowHeightGt,
        slideshowHeightLt, 
        btnParentWidthGt, 
        btnParentWidthLt, 
        textSize, 
        indicatorWidthGt, 
        indicatorWidthLt,
        indicatorSpanWidth,
        breakpoint, 
        mobileBreakpoint,
        childBreakpoint,
        headingBreakpoint
    }
) {
  // Keep monitor resolution changes
  console.log(`dimensions.width: ${dimensions.width}`);
  
  const mobileBtnRight = Math.floor((dimensions.width * 0.8) * -1);
  const mobileBtnWidth = Math.floor(dimensions.width * 0.1333);
  const mobileFontSize = Math.floor(dimensions.width * 0.08);
  const mobileBtnTop = Math.floor(dimensions.height * 0.5);

  return (
    <div className="mobileBtn"
      style={{
        
      }}
    >
        <a className="mobileBtn__body btnMobile btnMobile--mobile"
            href="#top"
            style={{
                position: "absolute",
                top: mobileBtnTop,
                fontSize: mobileFontSize,
                right: mobileBtnRight,
                height: mobileBtnWidth,
                width: mobileBtnWidth,
            }}
        >
            ^
        </a>
    </div>
  );
}

const items = [
    {
        title: "Evertex Sport Pant1",
        description: "Evertex Sport Pant 1",
        url: require("../../../images/products/PANT/pant1.jpg"),
    },
    {
        title: "Evertex Sport Pant2",
        description: "Evertex Sport Pant 2",
        url: require("../../../images/products/PANT/pant2.jpg"),
    },
    {
        title: "Evertex Sport Pant3",
        description: "Evertex Sport Pant 3",
        url: require("../../../images/products/PANT/pant3.jpg"),
    },
    {
        title: "Evertex Sport Pant4",
        description: "Evertex Sport Pant 4",
        url: require("../../../images/products/PANT/pant4.jpg"),
    },
    {
        title: "Evertex Sport Pant5",
        description: "Evertex Sport Pant 5",
        url: require("../../../images/products/PANT/pant5.jpg"),
    },
    {
        title: "Evertex Sport Pant6",
        description: "Evertex Sport Pant 6",
        url: require("../../../images/products/PANT/pant6.jpg"),
    },
    {
        title: "Evertex Sport Pant7",
        description: "Evertex Sport Pant 7",
        url: require("../../../images/products/PANT/pant7.jpg"),
    },
];

export default items;


import React, { useState, useEffect, Suspense } from "react";
import {BrowserRouter as Router, Route, Routes} from 'react-router-dom';
import MobileButton from "../../components/MobileButton/MobileButton";
import HomeInner from '../../pages/Home/HomeInner';
import AboutInnerLt from '../../pages/About/aboutInnerLt';
import ProductsInner from '../../pages/Products/productsInner'
import ContactInner from '../../pages/Contact/ContactInner';
import Nav from '../../components/Nav/Nav';
import Footer from '../../components/Footer/Footer/Footer';

const Mobile = ( {dimensions} ) => {

    // Monitor resolutions
    //console.log(`dimensions.width * 0.8 * 0.5: ${dimensions.width * 0.8 * 0.5}`)
    // For window.inner.width >= 1200
    //const slideshowWidthGt = Math.floor(dimensions.width*0.435);
    //const slideshowWidthGt = Math.floor(dimensions.width * 0.555);
    const slideshowWidthGt = '514px';
    //console.log(`slideshowWidthGt: ${slideshowWidthGt}`);
    const slideshowHeightGt = Math.floor(dimensions.width * 0.25);
    // For window.inner.width < 1200
    const slideshowWidthLt = Math.floor(dimensions.width * 0.7);
    //console.log(`slideshowWidthLt: ${slideshowWidthLt}`);

    const slideshowHeightLt = Math.floor(slideshowWidthLt * 1.4);
    //console.log(`slideshowWidth: ${slideshowWidth}`);
    const btnParentWidthLt = Math.floor(slideshowWidthLt * 0.12);
    const btnParentWidthGt = Math.floor(slideshowWidthGt * 0.12);
    const btnParentHeightGt = Math.floor(btnParentWidthGt * 0.5);
    const btnParentHeightLt = Math.floor(btnParentWidthLt * 0.5);
    const textSize = Math.floor(dimensions.width * 0.03);
    //const indicatorWidth = Math.floor(dimensions.width * 0.617);
    const indicatorWidthGt = Math.floor(dimensions.width * 0.617);
    const indicatorWidthLt = Math.floor(dimensions.width * 0.8);
    const indicatorSpanWidth = Math.floor(dimensions.width * 0.035);
    //console.log(`Factory Slideshow textSize: ${textSize}`);
    const childBreakpoint = 920;
    const mobileBreakpoint = 550;

    // About heading Hacker effects
    const headingHacker = () => {
      // Home About Products
      const letters = "abcdefghijklmnopqrstuvwxyz0123456789";
      const headingGt = document.querySelectorAll(".aboutGt__heading--h2");
      // Convert NodeList to an array
      const headingGtArray = Array.from(headingGt);
      
      headingGtArray.map((eachHeading) => {
        eachHeading.onmouseover = event => { 
          let iterations = 0;
          const dataValue = event.target.dataset.value;
          if (!dataValue) {
            console.log("Missing data-value attribute on the element with class: 'nav' ");
            return;
          } else {
            const interval = setInterval(() => {
            event.target.innerText = event.target.innerText.split("")
            .map((letter, index) => {
            // letters[Math.floor(Math.random() * 26)]
            if (index < iterations) {
              return event.target.dataset.value[index];
            }
            return letters[Math.floor(Math.random() * 36)]
            })
            .join("");
              if(iterations >= event.target.dataset.value.length) { 
              clearInterval(interval);
            }
            iterations += 1;    
            }, 80);
            }
          }
      })

      ///
      const headingLt = document.querySelectorAll(".aboutLt__heading--h2");
      // Convert NodeList to an array
      const headingLtArray = Array.from(headingLt);
      
      headingLtArray.map((eachHeading) => {
        eachHeading.onmouseover = event => { 
          let iterations = 0;
          const dataValue = event.target.dataset.value;
          if (!dataValue) {
            console.log("Missing data-value attribute on the element with class: 'nav' ");
            return;
          } else {
            const interval = setInterval(() => {
            event.target.innerText = event.target.innerText.split("")
            .map((letter, index) => {
            // letters[Math.floor(Math.random() * 26)]
            if (index < iterations) {
              return event.target.dataset.value[index];
            }
            return letters[Math.floor(Math.random() * 36)]
            })
            .join("");
              if(iterations >= event.target.dataset.value.length) { 
              clearInterval(interval);
            }
            iterations += 1;    
            }, 80);
            }
          }
      })
    }
      /////
      const paragraphHacker = () => {
      // Home About Products
      const letters = "abcdefghijklmnopqrstuvwxyz0123456789";
      const headingGt = document.querySelectorAll(".aboutGt__inner--text-p");
      // Convert NodeList to an array
      const headingGtArray = Array.from(headingGt);
      
      headingGtArray.map((eachHeading) => {
        eachHeading.onmouseover = event => { 
          let iterations = 0;
          const dataValue = event.target.dataset.value;
          if (!dataValue) {
            console.log("Missing data-value attribute on the element with class: 'nav' ");
            return;
          } else {
            const interval = setInterval(() => {
            event.target.innerText = event.target.innerText.split("")
            .map((letter, index) => {
            // letters[Math.floor(Math.random() * 26)]
            if (index < iterations) {
              return event.target.dataset.value[index];
            }
            return letters[Math.floor(Math.random() * 36)]
            })
            .join("");
              if(iterations >= event.target.dataset.value.length) { 
              clearInterval(interval);
            }
            iterations += 1;    
            }, 0.005);
            }
          }
      })
    }

    return (
      <>
        <MobileButton
          dimensions={dimensions} 
          slideshowWidthGt={slideshowWidthGt}
          slideshowWidthLt={slideshowWidthLt}
          slideshowHeightGt={slideshowHeightGt}
          slideshowHeightLt={slideshowHeightLt}
          btnParentWidthGt={btnParentWidthGt}
          btnParentWidthLt={btnParentWidthLt}
          btnParentHeightGt={btnParentHeightGt}
          btnParentHeightLt={btnParentHeightLt}
          indicatorWidthGt={indicatorWidthGt}
          indicatorWidthLt={indicatorWidthLt}
          indicatorSpanWidth={indicatorSpanWidth}
          childBreakpoint={childBreakpoint}
          mobileBreakpoint={mobileBreakpoint}
          headingHacker={headingHacker}
        />
        <Nav 
          dimensions={dimensions} 
          slideshowWidthGt={slideshowWidthGt}
          slideshowWidthLt={slideshowWidthLt}
          slideshowHeightGt={slideshowHeightGt}
          slideshowHeightLt={slideshowHeightLt}
          btnParentWidthGt={btnParentWidthGt}
          btnParentWidthLt={btnParentWidthLt}
          btnParentHeightGt={btnParentHeightGt}
          btnParentHeightLt={btnParentHeightLt}
          indicatorWidthGt={indicatorWidthGt}
          indicatorWidthLt={indicatorWidthLt}
          indicatorSpanWidth={indicatorSpanWidth}
          childBreakpoint={childBreakpoint}
          mobileBreakpoint={mobileBreakpoint}
          headingHacker={headingHacker}
        />
        <HomeInner 
          dimensions={dimensions} 
          slideshowWidthGt={slideshowWidthGt}
          slideshowWidthLt={slideshowWidthLt}
          slideshowHeightGt={slideshowHeightGt}
          slideshowHeightLt={slideshowHeightLt}
          btnParentWidthGt={btnParentWidthGt}
          btnParentWidthLt={btnParentWidthLt}
          btnParentHeightGt={btnParentHeightGt}
          btnParentHeightLt={btnParentHeightLt}
          indicatorWidthGt={indicatorWidthGt}
          indicatorWidthLt={indicatorWidthLt}
          indicatorSpanWidth={indicatorSpanWidth}
          childBreakpoint={childBreakpoint}
          mobileBreakpoint={mobileBreakpoint}
          headingHacker={headingHacker} 
        />
        <AboutInnerLt 
          dimensions={dimensions} 
          slideshowWidthGt={slideshowWidthGt}
          slideshowWidthLt={slideshowWidthLt}
          slideshowHeightGt={slideshowHeightGt}
          slideshowHeightLt={slideshowHeightLt}
          btnParentWidthGt={btnParentWidthGt}
          btnParentWidthLt={btnParentWidthLt}
          btnParentHeightGt={btnParentHeightGt}
          btnParentHeightLt={btnParentHeightLt}
          indicatorWidthGt={indicatorWidthGt}
          indicatorWidthLt={indicatorWidthLt}
          indicatorSpanWidth={indicatorSpanWidth}
          childBreakpoint={childBreakpoint}
          mobileBreakpoint={mobileBreakpoint}
          headingHacker={headingHacker}
          paragraphHacker={paragraphHacker} 
        />
        <ProductsInner 
          dimensions={dimensions} 
          slideshowWidthGt={slideshowWidthGt}
          slideshowWidthLt={slideshowWidthLt}
          slideshowHeightGt={slideshowHeightGt}
          slideshowHeightLt={slideshowHeightLt}
          btnParentWidthGt={btnParentWidthGt}
          btnParentWidthLt={btnParentWidthLt}
          btnParentHeightGt={btnParentHeightGt}
          btnParentHeightLt={btnParentHeightLt}
          indicatorWidthGt={indicatorWidthGt}
          indicatorWidthLt={indicatorWidthLt}
          indicatorSpanWidth={indicatorSpanWidth}
          childBreakpoint={childBreakpoint}
          mobileBreakpoint={mobileBreakpoint}
          headingHacker={headingHacker} 
        />
        <ContactInner 
          dimensions={dimensions} 
          slideshowWidthGt={slideshowWidthGt}
          slideshowWidthLt={slideshowWidthLt}
          slideshowHeightGt={slideshowHeightGt}
          slideshowHeightLt={slideshowHeightLt}
          btnParentWidthGt={btnParentWidthGt}
          btnParentWidthLt={btnParentWidthLt}
          btnParentHeightGt={btnParentHeightGt}
          btnParentHeightLt={btnParentHeightLt}
          indicatorWidthGt={indicatorWidthGt}
          indicatorWidthLt={indicatorWidthLt}
          indicatorSpanWidth={indicatorSpanWidth}
          childBreakpoint={childBreakpoint}
          mobileBreakpoint={mobileBreakpoint}
          headingHacker={headingHacker} 
        />
        <Footer 
          dimensions={dimensions} 
          slideshowWidthGt={slideshowWidthGt}
          slideshowWidthLt={slideshowWidthLt}
          slideshowHeightGt={slideshowHeightGt}
          slideshowHeightLt={slideshowHeightLt}
          btnParentWidthGt={btnParentWidthGt}
          btnParentWidthLt={btnParentWidthLt}
          btnParentHeightGt={btnParentHeightGt}
          btnParentHeightLt={btnParentHeightLt}
          indicatorWidthGt={indicatorWidthGt}
          indicatorWidthLt={indicatorWidthLt}
          indicatorSpanWidth={indicatorSpanWidth}
          childBreakpoint={childBreakpoint}
          mobileBreakpoint={mobileBreakpoint}
          headingHacker={headingHacker}
        />
      </>
    );
  };

export default Mobile;
import './App.scss';
import React, { useState, useEffect, Suspense } from "react";
import {BrowserRouter as Router, Route, Routes} from 'react-router-dom';
import setting from './setting.json';
import Home from './pages/Home/index';
import About from './pages/About/about';
import Products from './pages/Products/products'
import Contact from './pages/Contact/contact';
import Mobile from './layouts/Mobile/Mobile';
import StoneAge from './layouts/StoneAge/StoneAge';

const Loading = () => <div>Loading</div>;

function App() {
  let url = window.location.port;
  let basename = setting.production_url;
  if (url != "") {
    basename = "";
  } 

// Keep monitor resolution changes
const [dimensions, setDimensions] = useState({
  height: window.innerHeight,
  width: window.innerWidth,
});

useEffect(() => {
    function handleResize() {
      setDimensions({
        height: window.innerHeight,
        width: window.innerWidth,
      });
    }
    window.addEventListener("resize", handleResize);
    // Clear memory leak
    return (_) => {
      window.removeEventListener("resize", handleResize);
    };
});

  return (
    <>
      {dimensions.width >= 850 ? (
        <Router>
          <Routes>          
            <Route index path="/" element={<Home dimensions={dimensions} />} />
            <Route path="/about" element={<About dimensions={dimensions} />} />
            <Route path="/products" element={<Products dimensions={dimensions} />} />
            <Route path="/contact" element={<Contact dimensions={dimensions} />}  />
          </Routes>
        </Router>
        
      ) : dimensions.width >=300 ? (
        <Router>
          <Routes>          
            <Route index path="/" element={<Mobile dimensions={dimensions} />} />
            <Route path="/about" element={<About dimensions={dimensions} />} />
            <Route path="/products" element={<Products dimensions={dimensions} />} />
            <Route path="/contact" element={<Contact dimensions={dimensions} />} />
          </Routes>
        </Router>
      ) : (
        <Router>
          <Routes>
            <Route index path="/" element={<StoneAge dimensions={dimensions} />} />
            <Route path="/about" element={<StoneAge dimensions={dimensions} />} />
            <Route path="/products" element={<StoneAge dimensions={dimensions} />} />
            <Route path="/contact" element={<StoneAge dimensions={dimensions} />} />
          </Routes>
        </Router>
      )}
    </>
  )

}

export default App;

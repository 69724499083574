const items = [
    {
        title: "tee1",
        description: "Evertex Tee 1",
        url: require("../../../images/products/TEE/tee3.jpg"),
    },
    {
        title: "tee2",
        description: "Evertex Tee 2",
        url: require("../../../images/products/TEE/tee4.jpg"),
    },
    {
        title: "tee3",
        description: "Evertex Tee 3",
        url: require("../../../images/products/TEE/tee5.jpg"),
    },
    {
        title: "tee4",
        description: "Evertex Tee 4",
        url: require("../../../images/products/TEE/tee6.jpg"),
    },
    {
        title: "tee5",
        description: "Evertex Tee 5",
        url: require("../../../images/products/TEE/tee7.jpg"),
    },
    {
        title: "tee6",
        description: "Evertex Tee 6",
        url: require("../../../images/products/TEE/tee8.jpg"),
    },
    {
        title: "tee7",
        description: "Evertex Tee 7",
        url: require("../../../images/products/TEE/tee9.jpg"),
    },
    {
        title: "tee8",
        description: "Evertex Tee 8",
        url: require("../../../images/products/TEE/tee10.jpg"),
    },
];

export default items;


import "../Slideshow.scss";

const BraItem = ( {
    item, 
    width, 
    dimensions, 
    slideshowWidthGt, 
    slideshowWidthLt,
    slideshowHeightGt,
    slideshowHeightLt, 
    btnParentWidthGt, 
    btnParentWidthLt, 
    textSize, 
    indicatorWidthGt, 
    indicatorWidthLt,
    indicatorSpanWidth,
    breakpoint, 
    mobileBreakpoint
    }) => {
    //console.log(`dimensions.width: ${dimensions.width}`);
    const imgWidthLt = Math.floor(dimensions.width * 0.9);
    //console.log(`imgWidth: ${imgWidth}`);

    return (
        <div className="slideshowItem" style={{ width: width }}>
            <div></div>
            <img 
                className="slideshowItem--img" 
                src={item.url} 
                style={{
                    width: dimensions.width < 850 ? imgWidthLt : "95%",
                    height: dimensions.width >= 850 ? "" : dimensions.width >= 380 ? "" : "50%",
                    //width: dimensions.width >= 650 ? "" : imgWidth
                    //width: dimensions.width >= 650 ? "" : dimensions.width > 450 ? imgWidth : "27rem"
                }}
            />
            {/* <div className="slideshowItem--text">{item.description}</div> */}
        </div>
    )
}

export default BraItem;
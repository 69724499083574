import React, {useState, useEffect} from 'react';
import '../../sass/main.scss';
import './HomeInner.scss';
import HomeInnerGgt from './HomeInnerGgt';
import HomeInnerGt from './HomeInnerGt';
import HomeInnerLt from './HomeInnerLt';

const HomeInner = ( {
        dimensions,
        slideshowWidthGt,
        slideshowWidthLt,
        slideshowHeightGt,
        slideshowHeightLt,
        btnParentHeightGt,
        btnParentHeightLt,
        textSize,
        indicatorWidthGt,
        indicatorWidthLt,
        indicatorSpanWidth,
        childBreakpoint,
        mobileBreakpoint,
    } ) => {

    return dimensions.width >= 1250 ? 
    <HomeInnerGgt 
        dimensions={dimensions} 
        slideshowWidthGt={slideshowWidthGt} 
        slideshowWidthLt={slideshowWidthLt}
        slideshowHeightGt={slideshowHeightGt}
        slideshowHeightLt={slideshowHeightLt}
        btnParentHeightGt={btnParentHeightGt}
        btnParentHeightLt={btnParentHeightLt}
        textSize={textSize}
        indicatorWidthGt={indicatorWidthGt}
        indicatorWidthLt={indicatorWidthLt}
        indicatorSpanWidth={indicatorSpanWidth}
        childBreakpoint={childBreakpoint}
        mobileBreakpoint={mobileBreakpoint} 
    /> : 
    dimensions.width >= 850 ?
    <HomeInnerGt 
        dimensions={dimensions} 
        slideshowWidthGt={slideshowWidthGt} 
        slideshowWidthLt={slideshowWidthLt}
        slideshowHeightGt={slideshowHeightGt}
        slideshowHeightLt={slideshowHeightLt}
        btnParentHeightGt={btnParentHeightGt}
        btnParentHeightLt={btnParentHeightLt}
        textSize={textSize}
        indicatorWidthGt={indicatorWidthGt}
        indicatorWidthLt={indicatorWidthLt}
        indicatorSpanWidth={indicatorSpanWidth}
        childBreakpoint={childBreakpoint}
        mobileBreakpoint={mobileBreakpoint} 
    /> : 
    <HomeInnerLt 
        dimensions={dimensions} 
        slideshowWidthGt={slideshowWidthGt} 
        slideshowWidthLt={slideshowWidthLt}
        slideshowHeightGt={slideshowHeightGt}
        slideshowHeightLt={slideshowHeightLt}
        btnParentHeightGt={btnParentHeightGt}
        btnParentHeightLt={btnParentHeightLt}
        textSize={textSize}
        indicatorWidthGt={indicatorWidthGt}
        indicatorWidthLt={indicatorWidthLt}
        indicatorSpanWidth={indicatorSpanWidth}
        childBreakpoint={childBreakpoint}
        mobileBreakpoint={mobileBreakpoint} 
    />;
}

export default HomeInner;
const items = [
    {
        title: "Evertex Fabric1",
        description: "Evertex Fabric 1",
        url: require("../../../images/products/Fabric/fabric1.jpg"),
    },
    {
        title: "Evertex Fabric2",
        description: "Evertex Fabric 2",
        url: require("../../../images/products/Fabric/fabric2.jpg"),
    },
    {
        title: "Evertex Fabric3",
        description: "Evertex Fabric 3",
        url: require("../../../images/products/Fabric/fabric3.jpg"),
    },
    {
        title: "Evertex Fabric4",
        description: "Evertex Fabric 4",
        url: require("../../../images/products/Fabric/fabric4.jpg"),
    },
    {
        title: "Evertex Fabric5",
        description: "Evertex Fabric 5",
        url: require("../../../images/products/Fabric/fabric5.jpg"),
    },
    {
        title: "Evertex Fabric6",
        description: "Evertex Fabric 6",
        url: require("../../../images/products/Fabric/fabric6.jpg"),
    },
    {
        title: "Evertex Fabric7",
        description: "Evertex Fabric 7",
        url: require("../../../images/products/Fabric/fabric7.jpg"),
    },
];

export default items;


import React from 'react';
//import aboutImage from "../../images/about.png";
import aboutImage from "../../images/about/2.jpg";
import "./aboutInner.scss";
import OfficeSlideShow from "../../components/Slideshows/Office/OfficeSlideshow";
import FactorySlideShow from "../../components/Slideshows/Factory/FactorySlideshow";

const AboutInnerGt = ( {
      dimensions, 
      slideshowWidthGt,
      slideshowWidthLt,
      slideshowHeightGt,
      slideshowHeightLt,
      btnParentWidthGt,
      btnParentWidthLt,
      btnParentHeightGt,
      btnParentHeightLt,
      indicatorSpanWidth,
      indicatorWidthGt,
      indicatorWidthLt,
      imgWidth,
      imgHeight,
      breakpoint,
      mobileBreakpoint,
      projectileBreakpoint,
      headingHacker,
      paragraphHacker,
    } ) => {

    //console.log(`slideshowWidthLt: ${slideshowWidthLt}`);

    const aboutImgWidth = Math.floor(dimensions.width * 0.45);
    //console.log(`aboutImgWidth: ${aboutImgWidth}`);
    //const aboutImgMarginLeft = Math.floor(dimensions.width*0.192*0.5);
    //console.log(`aboutImageMarginLeft: ${aboutImgMarginLeft}`);
    const headingSize = Math.floor(dimensions.width * 0.036);
    
    //console.log(`headingSize: ${headingSize}`);
    const textSize = Math.floor(dimensions.width * 0.016);
    console.log(`textSize: ${textSize}`);

    // for aboutGt__intro
    const heading3Size = Math.floor(dimensions.width * 0.02);
    const liTextSize = Math.floor(dimensions.width * 0.016);
    const ulMarginLeft = Math.floor(dimensions.width * 0.04346);
    const gridWidth = Math.floor(dimensions.width*0.875*0.5);
    const gridWidthIntro = Math.floor((dimensions.width * 0.7)/3);
    const gridHeightIntro = Math.floor((dimensions.width * 0.875)/3);

    const officeHeightGt = Math.floor(dimensions.width * 0.1637);

    const aboutHeadingWidthLt = Math.floor(dimensions.width * 0.311);
    const aboutHeadingWidthGt = Math.floor(dimensions.width * 0.33);
  
  return (
    <div className='about-container'>   
      <div className="aboutGt">
        <div className="aboutGt__heading u-padding-top-small"
          style={{
            width: gridWidth
          }}
        >
                <h2 
                  className="aboutGt__heading--h2 frosted__children"
                  data-value="About Us"
                  onMouseEnter={()=> headingHacker()}
                  style={{
                    fontSize: headingSize,
                    width: dimensions.width >= 1000? aboutHeadingWidthGt: aboutHeadingWidthLt,
                  }}
                >
                  <strong>About Us</strong>
                </h2>
                <p>&nbsp;</p>
        </div>

        <div className="aboutGt__inner row-no">
                <div className="aboutGt__inner--text col-1-of-2"
                  style={{
                    width: gridWidth
                  }}
                >
                  <p 
                    data-value="Evertex (H.K.) Limited was founded in Hong Kong, we are a professional distributor in delivering our customers with knit garments, raw materials of knit fabrics and pattern design."
                    className="aboutGt__inner--text-p frosted__children"
                    style={{fontSize: textSize,}}
                  >
                    <strong>Evertex (H.K.) Limited was founded in Hong Kong, we are a professional distributor in delivering our customers with knit garments, raw materials of knit fabrics and pattern design.</strong>
                  </p>
                  <p>&nbsp;</p>
                  <p 
                    data-value="We have experienced partners' management teams with strong sample development skills and profound experiences in monitoring the products quality of our manufacturing partners in Mainland, China."
                    className="aboutGt__inner--text-p frosted__children"
                    style={{fontSize: textSize,}}
                  >
                    <strong>We have experienced partners' management teams with strong sample development skills and profound experiences in monitoring the products quality of our manufacturing partners in Mainland, China.</strong>
                  </p>
                  <p>&nbsp;</p>
                  <p 
                    data-value="The products are exported to the United States, Australia, and many other countries and the knit facbrics are exported to all over the world."
                    className="aboutGt__inner--text-p frosted__children"
                    style={{fontSize: textSize,}}
                  >
                    <strong>The products are exported to the United States, Australia, and many other countries and the knit facbrics are exported to all over the world.</strong>
                  </p>
                  <p>&nbsp;</p>
                  
                  {/* <p className="about__inner--cert">
                  Certifications Achieved:
                  </p> */}

                  {/* <ul className="about__inner--ul about__inner--cert">
                    <li>STANDARD 100 by OEKO-TEX®</li>
                    <li>Workplace Conditions Assessment (WCA)</li>
                    <li>Higg Facility Module</li>
                  </ul> */}

                  {/* <div 
                    className="about__inner--container row"
                    style={{
                      // To over-ride margin values in _grid.scss
                      marginLeft: "2rem",
                      marginBottom: "0",
                    }}
                  >
                    <div className="about__inner--container--img col-1-of-3">
                      <img src={ulImage1} alt="about image1" />
                    </div>
                    <div className="about__inner--container--img col-1-of-3">
                      <img src={ulImage2} alt="about image2" />
                    </div>
                    <div className="about__inner--container--img col-1-of-3">
                      <img src={ulImage3} alt="about image3" />
                    </div>
                  </div> */}
                </div>
                <div className="aboutGt__inner--about col-1-of-2"
                  style={{
                    width: gridWidth,
                    margin: "0 2rem",
                  }}
                >
                  <img 
                    className="aboutGt__inner--about-aboutImg"
                    src={aboutImage} 
                    alt="about_image" 
                    style={{
                      borderRadius: "2rem",
                      width: aboutImgWidth
                      //marginLeft: aboutImgMarginLeft,
                      //paddingRight: "10rem",
                    }}
                  />
                </div>
        </div>

        <div className="aboutGt__intro features">
          <div className="aboutGt__intro--row col-1-of-3 frosted__children"
            style={{
              width: gridWidthIntro,
              height: gridHeightIntro,
            }}
          >
            <h3 className="aboutGt__intro--row-h3"
                data-value="Distributions:"
                style={{
                  fontSize: heading3Size,
                }}
            >
              <strong>Distributions:</strong>
            </h3>
            <ul className="aboutGt__intro--row-ul"
              style={{
                marginLeft: ulMarginLeft,
              }}
            >
              <li className="aboutGt__intro--row-li"
                  data-value="yoga wear, active wear,"
                  style={{fontSize: liTextSize,}}
              >
                <strong>yoga wear, active wear,</strong>
              </li>
              <li className="aboutGt__intro--row-li"
                  data-value="sports wear, sports bras,"
                  style={{fontSize: liTextSize,}}
              >
                <strong>sports wear, sports bras,</strong>
              </li>
              <li className="aboutGt__intro--row-li"
                  data-value="leggings, tank tops,"
                  style={{fontSize: liTextSize,}}
              >
                <strong>leggings, tank tops,</strong>
              </li>
              
              <li className="aboutGt__intro--row-li"
                  data-value="T-shirts, shorts,"
                  style={{fontSize: liTextSize,}}
              >
                <strong>T-shirts, shorts,</strong>
              </li>
              <li className="aboutGt__intro--row-li"
                  data-value="joggers, dresses"
                  style={{fontSize: liTextSize,}}
              >
                <strong>joggers, dresses</strong>
              </li>
              <li className="aboutGt__intro--row-li"
                  data-value="with adult size range:"
                  style={{fontSize: liTextSize,}}
              >
                <strong>with adult size range:</strong>
              </li>
              <li className="aboutGt__intro--row-li"
                  data-value="XS, S, M, L, XL, XXL"
                  style={{fontSize: liTextSize,}}
              >
                <strong>XS, S, M, L, XL, XXL</strong>
              </li>
            </ul>
          </div>
          <div className="aboutGt__intro--row col-1-of-3 frosted__children"
            style={{
              width: gridWidthIntro,
              height: gridHeightIntro,
            }}
          >
            <h3 className="aboutGt__intro--row-h3"
                data-value="Fabric Research:"
                style={{
                  fontSize: heading3Size,
                }}
            >
              <strong>Fabric Research:</strong>
            </h3>
            <ul className="aboutGt__intro--row-ul"
              style={{
                marginLeft: ulMarginLeft,
              }}
            >
              <li className="aboutGt__intro--row-li"
                  data-value="OEM & ODM Projects"
                  style={{fontSize: liTextSize,}}
              >
                <strong>OEM & ODM Projects</strong>
              </li>
              <li className="aboutGt__intro--row-li"
                  data-value="doing pattern design"
                  style={{fontSize: liTextSize,}}
              >
                <strong>doing pattern design</strong>
              </li>
              <li className="aboutGt__intro--row-li"
                  data-value="fabric research"
                  style={{fontSize: liTextSize,}}
              >
                <strong>fabric research</strong>
              </li>
              <li className="aboutGt__intro--row-li"
                  data-value="customers' requirements"
                  style={{fontSize: liTextSize,}}
              >
                <strong>customers' requirements</strong>
              </li>
            </ul>
          </div>
          <div className="aboutGt__intro--row col-1-of-3 frosted__children"
            style={{
              width: gridWidthIntro,
              height: gridHeightIntro,
            }}
          >
            <h3 className="aboutGt__intro--row-h3"
                data-value="Production coordination:"
                style={{
                  fontSize: heading3Size,
                }}
            >
              <strong>Production coordination:</strong>
            </h3>
            <ul className="aboutGt__intro--row-ul"
              style={{
                marginLeft: ulMarginLeft,
              }}
            >
              <li className="aboutGt__intro--row-li"
                  data-value="skilled in sampling"
                  style={{fontSize: liTextSize,}}
              >
                <strong>
                  skilled in sampling
                </strong>
              </li>
              <li className="aboutGt__intro--row-li"
                  data-value="delivery on schedule"
                  style={{fontSize: liTextSize,}}
              >
                <strong>delivery on schedule</strong>
              </li>
              <li className="aboutGt__intro--row-li"
                  data-value="high quality services"
                  style={{fontSize: liTextSize,}}
              >
                <strong>high quality services</strong>
              </li>
              <li className="aboutGt__intro--row-li"
                  data-value="high quality products"
                  style={{fontSize: liTextSize,}}
              >
                <strong>high quality products</strong>
              </li>
              <li className="aboutGt__intro--row-li"
                  data-value="our customers are"
                  style={{fontSize: liTextSize,}}
              >
                <strong>our customers are</strong>
              </li>
              <li className="aboutGt__intro--row-li"
                  data-value="our customers are"
                  style={{fontSize: liTextSize,}}
              >
                <strong>all over the world</strong>
              </li>
            </ul>
          </div>
        </div>
              
        <div className="aboutGt__bottom">
                    <div className="aboutGt__bottom--row row-no" >
                      <div className="aboutGt__bottom--row-text col-1-of-2"
                        style={{
                          width: gridWidth
                        }}
                      >
                        <h2 className="aboutGt__bottom--row-text--h2 frosted__children"
                          style={{ 
                            height: officeHeightGt,
                            fontSize: headingSize, 
                          }}
                        >
                          <strong>Evertex Office</strong>
                        </h2>
                      </div>
                      <div className="aboutGt__bottom--row-slideshow col-1-of-2"
                        style={{
                          width: gridWidth
                        }}
                      >
                        <OfficeSlideShow 
                          dimensions={dimensions}
                          slideshowWidthGt={slideshowWidthGt}
                          slideshowWidthLt={slideshowWidthLt}
                          slideshowHeightGt={slideshowHeightGt}
                          slideshowHeightLt={slideshowHeightLt}
                          btnParentWidthGt={btnParentWidthGt}
                          btnParentWidthLt={btnParentWidthLt}
                          btnParentHeightGt={btnParentHeightGt}
                          btnParentHeightLt={btnParentHeightLt}
                          indicatorWidthGt={indicatorWidthGt}
                          indicatorWidthLt={indicatorWidthLt}
                          imgWidth={imgWidth}
                          imgHeight={imgHeight}
                          breakpoint={breakpoint}
                          mobileBreakpoint={mobileBreakpoint}
                          projectileBreakpoint={projectileBreakpoint}
                          indicatorSpanWidth={indicatorSpanWidth}
                        />
                      </div>
                    </div>
                    
                    <div className="aboutGt__bottom--row row-no" >
                      <div className="aboutGt__bottom--row-text col-1-of-2"
                        style={{
                          width: gridWidth
                        }}
                      >
                        <h2 className="aboutGt__bottom--row-text--h2 frosted__children"
                          style={{
                            height: officeHeightGt,
                            fontSize: headingSize,
                          }}
                        >
                          <strong>Partners' factories</strong>
                        </h2>
                      </div>
                      <div className="aboutGt__bottom--row-slideshow col-1-of-2 u-margin-bottom-big"
                        style={{
                          width: gridWidth
                        }}
                      >
                        <FactorySlideShow 
                          dimensions={dimensions}
                          slideshowWidthGt={slideshowWidthGt}
                          slideshowWidthLt={slideshowWidthLt}
                          slideshowHeightGt={slideshowHeightGt}
                          slideshowHeightLt={slideshowHeightLt}
                          btnParentWidthGt={btnParentWidthGt}
                          btnParentWidthLt={btnParentWidthLt}
                          btnParentHeightGt={btnParentHeightGt}
                          btnParentHeightLt={btnParentHeightLt}
                          indicatorWidthGt={indicatorWidthGt}
                          indicatorWidthLt={indicatorWidthLt}
                          imgWidth={imgWidth}
                          imgHeight={imgHeight}
                          breakpoint={breakpoint}
                          mobileBreakpoint={mobileBreakpoint}
                          projectileBreakpoint={projectileBreakpoint}
                          indicatorSpanWidth={indicatorSpanWidth} 
                        />
                      </div>
                    </div>
        </div>
      </div> 
    </div>
  );
};

export default AboutInnerGt;
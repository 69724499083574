import StoneAgePhoto from "../../images/stoneAge/stoneAge.jpg";
import "./StoneAge.scss";

const StoneAge = ( {dimensions} ) => {
    return (
        <div className="poorGuy frosted">
            <img 
              className="stone-age" 
              src={StoneAgePhoto}
              alt="stone age photo"
              style={{
                width: Math.floor(dimensions.width * 0.78),
                height: Math.floor(dimensions.height * 0.25),
              }}
            ></img>
            <h1 className="poorGuy__h1 frosted__children">
              <p className="poorGuy__h1-alert">
                Wooah! Your mobile device is too OLD SKULL!
              </p>
              <p className="poorGuy__h1-alert">&nbsp;</p>
              <p className="poorGuy__h1-alert">
                It only has less than 300px!
              </p>
              <p className="poorGuy__h1-alert">&nbsp;</p>
              <p className="poorGuy__h1-alert">
                You should stop using the internet...
              </p>
              <p className="poorGuy__h1-alert">
                until someday you get a decent mobile device...
              </p>
            </h1>
        </div>
    )
}

export default StoneAge;
const items = [
    {
        title: "office1",
        description: "description1",
        url: require("../../../images/office/office1.jpg"),
    },
    {
        title: "office2",
        description: "description2",
        url: require("../../../images/office/office2.jpg"),
    },
    {
        title: "office3",
        description: "description3",
        url: require("../../../images/office/office3.jpg"),
    },
    {
        title: "office5",
        description: "description5",
        url: require("../../../images/office/office5.jpg"),
    },
];

export default items;


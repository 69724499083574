import "./HomeInner.scss";
import React, {useState, useEffect} from "react";
import cloth1 from "../../images/home/1.jpg";
import cloth2 from "../../images/home/2.jpg";
import cloth3 from "../../images/home/3.jpg";
import cloth4 from "../../images/home/4.jpg";
import cloth5 from "../../images/home/5.jpg";
import cloth6 from "../../images/home/6.jpg";
import cloth7 from "../../images/home/7.jpg";
import cloth8 from "../../images/home/8.jpg";

const HomeInnerGgt = ( {
    dimensions,
        slideshowWidthGt,
        slideshowWidthLt,
        slideshowHeightGt,
        slideshowHeightLt,
        btnParentHeightGt,
        btnParentHeightLt,
        textSize,
        indicatorWidthGt,
        indicatorWidthLt,
        indicatorSpanWidth,
        childBreakpoint,
        mobileBreakpoint,
    } ) => {
    const imgWidth = Math.floor(dimensions.width*0.22);
    const imgHeight = Math.floor(imgWidth * 1.276);
    const projectileBreakpoint = 2200;

    return (
        <div className="home-container">
            <div className="ggt-container">
                <div className="ggt-container__session row frosted">
                    <div className="ggt-container__session--img col-1-of-4 frosted__children"
                        style={{
                            width: imgWidth,
                            height: imgHeight,
                            borderRadius: dimensions.width < projectileBreakpoint ? "2rem" : "4rem",
                        }}
                    >
                        <img 
                        className="ggt-container__session--img" 
                        src={cloth1} 
                        style={{
                            width: imgWidth,
                            height: imgHeight,
                            borderRadius: dimensions.width < projectileBreakpoint ? "2rem" : "4rem",
                        }}
                        />
                    </div>
                    <div className="ggt-container__session--img col-1-of-4 frosted__children"
                        style={{
                            width: imgWidth,
                            height: imgHeight,
                            borderRadius: dimensions.width < projectileBreakpoint ? "2rem" : "4rem",
                        }}
                    >
                        <img 
                        className="ggt-container__session--img" 
                        src={cloth2}
                        style={{
                            width: imgWidth,
                            height: imgHeight,
                            borderRadius: dimensions.width < projectileBreakpoint ? "2rem" : "4rem",
                        }} 
                        />
                    </div>
                    <div className="ggt-container__session--img col-1-of-4 frosted__children"
                        style={{
                            width: imgWidth,
                            height: imgHeight,
                            borderRadius: dimensions.width < projectileBreakpoint ? "2rem" : "4rem",
                        }}
                    >
                        <img 
                        className="ggt-container__session--img" 
                        src={cloth3} 
                        style={{
                            width: imgWidth,
                            height: imgHeight,
                            borderRadius: dimensions.width < projectileBreakpoint ? "2rem" : "4rem",
                        }} 
                        />
                    </div>
                    <div className="ggt-container__session--img col-1-of-4 frosted__children"
                        style={{
                            width: imgWidth,
                            height: imgHeight,
                            borderRadius: dimensions.width < projectileBreakpoint ? "2rem" : "4rem",
                        }}
                    >
                        <img 
                        className="ggt-container__session--img" 
                        src={cloth4} 
                        style={{
                            width: imgWidth,
                            height: imgHeight,
                            borderRadius: dimensions.width < projectileBreakpoint ? "2rem" : "4rem",
                        }} 
                        />
                    </div>
                </div>

                <div className="ggt-container__session row frosted">
                    <div className="ggt-container__session--img col-1-of-4 frosted__children"
                        style={{
                            width: imgWidth,
                            height: imgHeight,
                            borderRadius: dimensions.width < projectileBreakpoint ? "2rem" : "4rem",
                        }}
                    >
                        <img 
                        className="ggt-container__session--img" 
                        src={cloth5} 
                        style={{
                            width: imgWidth,
                            height: imgHeight,
                            borderRadius: dimensions.width < projectileBreakpoint ? "2rem" : "4rem",
                        }} 
                        />
                    </div>
                    <div className="ggt-container__session--img col-1-of-4 frosted__children"
                        style={{
                            width: imgWidth,
                            height: imgHeight,
                            borderRadius: dimensions.width < projectileBreakpoint ? "2rem" : "4rem",
                        }}
                    >
                        <img 
                        className="ggt-container__session--img" 
                        src={cloth6}
                        style={{
                            width: imgWidth,
                            height: imgHeight,
                            borderRadius: dimensions.width < projectileBreakpoint ? "2rem" : "4rem",
                        }}  
                        />
                    </div>
                    <div className="ggt-container__session--img col-1-of-4 frosted__children"
                        style={{
                            width: imgWidth,
                            height: imgHeight,
                            borderRadius: dimensions.width < projectileBreakpoint ? "2rem" : "4rem",
                        }} 
                    >
                        <img 
                        className="ggt-container__session--img" 
                        src={cloth7}
                        style={{
                            width: imgWidth,
                            height: imgHeight,
                            borderRadius: dimensions.width < projectileBreakpoint ? "2rem" : "4rem",
                        }}  
                        />
                    </div>
                    <div className="ggt-container__session--img col-1-of-4 frosted__children"
                        style={{
                            width: imgWidth,
                            height: imgHeight,
                            borderRadius: dimensions.width < projectileBreakpoint ? "2rem" : "4rem",
                        }} 
                    >
                        <img 
                        className="ggt-container__session--img" 
                        src={cloth8} 
                        style={{
                            width: imgWidth,
                            height: imgHeight,
                            borderRadius: dimensions.width < projectileBreakpoint ? "2rem" : "4rem",
                        }} 
                        />
                    </div>
                </div>
            </div>
        </div>
    )
}

export default HomeInnerGgt;
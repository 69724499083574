const items = [
    {
        title: "sport suit1",
        description: "Evertex Sport Suits 1",
        url: require("../../../images/products/SPORT/sport1.jpg"),
    },
    {
        title: "sport suit2",
        description: "Evertex Sport Suits 2",
        url: require("../../../images/products/SPORT/sport2.jpg"),
    },
    {
        title: "sport suit3",
        description: "Evertex Sport Suits 3",
        url: require("../../../images/products/SPORT/sport3.jpg"),
    },
    {
        title: "sport suit4",
        description: "Evertex Sport Suits 4",
        url: require("../../../images/products/SPORT/sport4.jpg"),
    },
    {
        title: "sport suit5",
        description: "Evertex Sport Suits 5",
        url: require("../../../images/products/SPORT/sport5.jpg"),
    },
    {
        title: "sport suit6",
        description: "Evertex Sport Suits 6",
        url: require("../../../images/products/SPORT/sport6.jpg"),
    },
    {
        title: "sport suit7",
        description: "Evertex Sport Suits 7",
        url: require("../../../images/products/SPORT/sport7.jpg"),
    },
    {
        title: "sport suit8",
        description: "Evertex Sport Suits 8",
        url: require("../../../images/products/SPORT/sport8.jpg"),
    },
    {
        title: "sport suit9",
        description: "Evertex Sport Suits 9",
        url: require("../../../images/products/SPORT/sport9.jpg"),
    },
];

export default items;


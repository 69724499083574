import "./HomeInner.scss";
import React, {useState, useEffect} from "react";
import cloth1 from "../../images/home/1.jpg";
import cloth2 from "../../images/home/2.jpg";
import cloth3 from "../../images/home/3.jpg";
import cloth4 from "../../images/home/4.jpg";
import cloth5 from "../../images/home/5.jpg";
import cloth6 from "../../images/home/6.jpg";
import cloth7 from "../../images/home/7.jpg";
import cloth8 from "../../images/home/8.jpg";

const HomeInnerLt = ( {
    dimensions,
    slideshowWidthGt,
    slideshowWidthLt,
    slideshowHeightGt,
    slideshowHeightLt,
    btnParentHeightGt,
    btnParentHeightLt,
    textSize,
    indicatorWidthGt,
    indicatorWidthLt,
    indicatorSpanWidth,
    childBreakpoint,
    mobileBreakpoint,
    } ) => {

    return (
        <div className="home-container">
            <div className="lt-container frosted">
                <img className="lt-container--img frosted__children" src={cloth1} />
                <img className="lt-container--img frosted__children" src={cloth2} />
                <img className="lt-container--img frosted__children" src={cloth3} />
                <img className="lt-container--img frosted__children" src={cloth4} />
                <img className="lt-container--img frosted__children" src={cloth5} />
                <img className="lt-container--img frosted__children" src={cloth6} />
                <img className="lt-container--img frosted__children" src={cloth7} />
                <img className="lt-container--img frosted__children" src={cloth8} />
            </div>
        </div>
    )
}

export default HomeInnerLt;
const items = [
    {
        title: "Evertex Sports Bra1",
        description: "Evertex Sports Bra 1",
        url: require("../../../images/products/BRA/bra1.jpg"),
    },
    {
        title: "Evertex Sports Bra2",
        description: "Evertex Sports Bra 2",
        url: require("../../../images/products/BRA/bra2.jpg"),
    },
    {
        title: "Evertex Sports Bra3",
        description: "Evertex Sports Bra 3",
        url: require("../../../images/products/BRA/bra3.jpg"),
    },
    {
        title: "Evertex Sports Bra4",
        description: "Evertex Sports Bra 4",
        url: require("../../../images/products/BRA/bra4.jpg"),
    },
    {
        title: "Evertex Sports Bra5",
        description: "Evertex Sports Bra 5",
        url: require("../../../images/products/BRA/bra5.jpg"),
    },
    {
        title: "Evertex Sports Bra6",
        description: "Evertex Sports Bra 6",
        url: require("../../../images/products/BRA/bra6.jpg"),
    },
    {
        title: "Evertex Sports Bra7",
        description: "Evertex Sports Bra 7",
        url: require("../../../images/products/BRA/bra7.jpg"),
    },
    {
        title: "Evertex Sports Bra8",
        description: "Evertex Sports Bra 8",
        url: require("../../../images/products/BRA/bra8.jpg"),
    },
    {
        title: "Evertex Sports Bra9",
        description: "Evertex Sports Bra 9",
        url: require("../../../images/products/BRA/bra9.jpg"),
    },
];

export default items;


const items = [
    {
        title: "Evertex Yoga Suit 1",
        description: "Evertex Yoga Suit 1",
        url: require("../../../images/products/YOGA/yoga2.jpg"),
    },
    {
        title: "Evertex Yoga Suit 2",
        description: "Evertex Yoga Suit 2",
        url: require("../../../images/products/YOGA/yoga3.jpg"),
    },
    {
        title: "Evertex Yoga Suit 3",
        description: "Evertex Yoga Suit 3",
        url: require("../../../images/products/YOGA/yoga4.jpg"),
    },
    {
        title: "Evertex Yoga Suit 4",
        description: "Evertex Yoga Suit 4",
        url: require("../../../images/products/YOGA/yoga5.jpg"),
    },
    {
        title: "Evertex Yoga Suit 5",
        description: "Evertex Yoga Suit 5",
        url: require("../../../images/products/YOGA/yoga6.jpg"),
    },
    {
        title: "Evertex Yoga Suit 6",
        description: "Evertex Yoga Suit 6",
        url: require("../../../images/products/YOGA/yoga7.jpg"),
    },
    {
        title: "Evertex Yoga Suit 7",
        description: "Evertex Yoga Suit 7",
        url: require("../../../images/products/YOGA/yoga8.jpg"),
    },
    {
        title: "Evertex Yoga Suit 8",
        description: "Evertex Yoga Suit 8",
        url: require("../../../images/products/YOGA/yoga9.jpg"),
    },
    {
        title: "Evertex Yoga Suit 9",
        description: "Evertex Yoga Suit 9",
        url: require("../../../images/products/YOGA/yoga10.jpg"),
    },
];

export default items;


import "../Slideshow.scss";

const PantItem = ( {
    item, 
    width, 
    dimensions, 
    slideshowWidthGt, 
    slideshowWidthLt,
    slideshowHeightGt,
    slideshowHeightLt, 
    btnParentWidthGt, 
    btnParentWidthLt, 
    textSize, 
    indicatorWidthGt, 
    indicatorWidthLt,
    indicatorSpanWidth,
    imgWidth,
    imgHeight,
    breakpoint, 
    mobileBreakpoint,
    projectileBreakpoint,
    }) => {
    //console.log(`dimensions.width: ${dimensions.width}`);
    //console.log(`imgWidth: ${imgWidth}`);

    return (
        <div className="slideshowItem" 
            style={{ 
                //width: width ,
                width: dimensions.width >= projectileBreakpoint ? imgWidth : width,
                height: dimensions.width >= projectileBreakpoint ? imgHeight : ""
            }}
        >
            <div></div>
            <img 
                className="slideshowItem--img" 
                src={item.url} 
                style={{
                    //width: dimensions.width < 850 ? imgWidthLt : "95%",
                    //height: dimensions.width >= 850 ? "" : dimensions.width >= 380 ? "" : "50%",
                    width: dimensions.width >= projectileBreakpoint ? imgWidth : "",
                    height: dimensions.width >= projectileBreakpoint ? imgHeight : "",
                }}
            />
            {/* <div className="slideshowItem--text">{item.description}</div> */}
        </div>
    )
}

export default PantItem;
import React from "react";
import './FooterGt850.scss';
import locationImage from "../../../images/location.png";
import callImage from "../../../images/call.png";
import emailImage from "../../../images/email.png";
import circlecImage from "../../../images/circlec.png";

export default function FooterGt850 () {

    return (
        <div className="containerGt row">
                <div className="containerGt__session col-1-of-4">
                    <div className="containerGt__session--img location">
                        <img src={locationImage} alt="Location"
                        /> 
                    </div>
                    <div className="containerGt__session--text">
                        <strong>Our Office</strong>
                        <br/>
                        <strong>Unit 2711,</strong>
                        <br/>
                        <strong>Trendy Centre,</strong> 
                        <br/>
                        <strong>682 Castle Peak Rd,</strong>
                        <br/>
                        <strong>Lai Chi Kok</strong>
                    </div>
                </div>
                <div className="containerGt__session col-1-of-4">
                    <div className="containerGt__session--img">
                        <img 
                            src={callImage}
                            alt="Call"
                        />
                    </div>
                    <div className="containerGt__session--text">
                        <strong>Call</strong>
                        <br/>
                        <strong>Tel:(852) 2728 3990</strong>
                        <br/>
                        <strong>Fax:(852) 2728 3999</strong>
                    </div>                   
                </div>
                <div className="containerGt__session col-1-of-4">
                    <div className="containerGt__session--img">
                        <img 
                            src={emailImage}
                            alt="Email"
                        />
                    </div>
                    <div className="containerGt__session--text">
                        <strong>email</strong>
                        <br/>
                        <strong>enquiry@evertex.hk</strong>
                    </div>                   
                </div>
                <div className="containerGt__session col-1-of-4">
                    <div className="containerGt__session--img">
                        <img 
                            src={circlecImage}
                            alt="CircleC"
                        />  
                    </div>
                    <div className="containerGt__session--text">
                        <strong>2023 by Evertex (H.K.) Limited</strong>
                    </div>                   
                </div>
        </div>
    )
}

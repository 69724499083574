import React, {useState, useEffect} from 'react';
import './productsInner.scss';
import SportSlideshow from '../../components/Slideshows/Sport/SportSlideshow';
import YogaSlideshow from '../../components/Slideshows/Yoga/YogaSlideshow';
import BraSlideshow from '../../components/Slideshows/Bra/BraSlideshow';
import PantSlideshow from '../../components/Slideshows/Pant/PantSlideshow';
import FabricSlideshow from '../../components/Slideshows/Fabric/FabricSlideshow';
import TeeSlideshow from '../../components/Slideshows/Tee/TeeSlideshow';


const ProductsInner = ( {dimensions} ) => {
  const headingGtSize = Math.floor(dimensions.width * 0.035);
  const headingLtSize = Math.floor(dimensions.width * 0.046);
  
  const breakpoint = 1100;
  const mobileBreakpoint = 600;
  const fontBreakpoint = 860;

  // Monitor resolutions
  //console.log(`dimensions.width * 0.8 * 0.5: ${dimensions.width * 0.8 * 0.5}`)
  // For window.inner.width >= 
  //const slideshowWidthGt = Math.floor(dimensions.width*0.435);
  const slideshowWidthGt = '514px';
  // For window.inner.width < 
  const slideshowWidthLt = Math.floor(dimensions.width*0.879);
  //console.log(`slideshowWidth: ${slideshowWidth}`);
  
  const slideshowHeightGt = Math.floor(dimensions.width * 0.25);
  const slideshowHeightLt = Math.floor(slideshowWidthLt * 2.2);

  const btnParentWidthLt = Math.floor(slideshowWidthLt * 0.12);
  const btnParentWidthGt = Math.floor(slideshowWidthGt * 0.12);
  const textSize = Math.floor(dimensions.width * 0.03);
  const indicatorWidthGt = Math.floor(dimensions.width * 0.617);
  const indicatorWidthLt = Math.floor(dimensions.width * 0.8);
  const indicatorSpanWidth = Math.floor(dimensions.width * 0.03);

  const imgWidth = Math.floor(dimensions.width*0.35);
  const imgHeight = Math.floor(imgWidth * 1.276);
  const projectileBreakpoint = 2200;

  // Hacker effects
  const Hacker = () => {
  // Home About Products
  const letters = "abcdefghijklmnopqrstuvwxyz0123456789";
  const tabs = document.querySelectorAll(".products__heading--h2");
  // Convert NodeList to an array
  const tabsArray = Array.from(tabs);
  tabsArray.map((tab) => {
    tab.onmouseover = event => { 
      let iterations = 0;
      const dataValue = event.target.dataset.value;
      if (!dataValue) {
        console.log("Missing data-value attribute on the element with class: 'nav' ");
        return;
      } else {
        const interval = setInterval(() => {
        event.target.innerText = event.target.innerText.split("")
        .map((letter, index) => {
        // letters[Math.floor(Math.random() * 26)]
        if (index < iterations) {
          return event.target.dataset.value[index];
        }
        return letters[Math.floor(Math.random() * 36)]
        })
        .join("");
          if(iterations >= event.target.dataset.value.length) { 
          clearInterval(interval);
        }
        iterations += 1;    
        }, 80);
        }
      }
    })
  }

  return (
    <>
      <div className="products__heading">
          <h2 
            className="products__heading--h2"
            data-value="Products"
            onMouseEnter={() => Hacker()}
            style={{
              fontSize: dimensions.width >= fontBreakpoint ? headingGtSize : headingLtSize,
              textAlign: dimensions.width >= breakpoint ? "" : "center"
            }}
          >
            <strong>Products</strong>
          </h2>
          <p>&nbsp;</p>
        </div>

      {/* For Product Lines 1 & 2 */}
      <div className="products row-no"
        style={{
          //display: dimensions.width >= breakpoint ? "flex" : "",
          display: dimensions.width >= breakpoint ? "flex" : "",
          justifyContent: dimensions.width >= breakpoint ? "center" : "",
          alignItems: dimensions.width >= breakpoint ? "center" : "",
        }}
      >
        <div className="products__slideshow col-1-of-2">
          <SportSlideshow 
            dimensions={dimensions}
            slideshowWidthGt={slideshowWidthGt} 
            slideshowWidthLt={slideshowWidthLt}
            slideshowHeightGt={slideshowHeightGt}
            slideshowHeightLt={slideshowHeightLt}
            btnParentWidthGt={btnParentWidthGt}
            btnParentWidthLt={btnParentWidthLt}
            textSize={textSize}
            indicatorWidthGt={indicatorWidthGt}
            indicatorWidthLt={indicatorWidthLt}
            indicatorSpanWidth={indicatorSpanWidth}
            imgWidth={imgWidth}
            imgHeight={imgHeight}
            mobileBreakpoint={mobileBreakpoint}
            projectileBreakpoint={projectileBreakpoint}
          />
        </div>
        <div className="products__slideshow col-1-of-2">
          <YogaSlideshow 
            dimensions={dimensions}
            slideshowWidthGt={slideshowWidthGt} 
            slideshowWidthLt={slideshowWidthLt}
            slideshowHeightGt={slideshowHeightGt}
            slideshowHeightLt={slideshowHeightLt}
            btnParentWidthGt={btnParentWidthGt}
            btnParentWidthLt={btnParentWidthLt}
            textSize={textSize}
            indicatorWidthGt={indicatorWidthGt}
            indicatorWidthLt={indicatorWidthLt}
            indicatorSpanWidth={indicatorSpanWidth}
            imgWidth={imgWidth}
            imgHeight={imgHeight}
            mobileBreakpoint={mobileBreakpoint}
            projectileBreakpoint={projectileBreakpoint}
          />
        </div>
      </div>

      {/* For Product Lines 3 & 4 */}
      <div className="products row-no"
        style={{
          display: dimensions.width >= breakpoint ? "flex" : "",
          justifyContent: dimensions.width >= breakpoint ? "center" : "",
          alignItems: dimensions.width >= breakpoint ? "center" : "",
        }}
      >
        <div className="products__slideshow col-1-of-2">
          <BraSlideshow
            dimensions={dimensions}
            slideshowWidthGt={slideshowWidthGt} 
            slideshowWidthLt={slideshowWidthLt}
            slideshowHeightGt={slideshowHeightGt}
            slideshowHeightLt={slideshowHeightLt}
            btnParentWidthGt={btnParentWidthGt}
            btnParentWidthLt={btnParentWidthLt}
            textSize={textSize}
            indicatorWidthGt={indicatorWidthGt}
            indicatorWidthLt={indicatorWidthLt}
            indicatorSpanWidth={indicatorSpanWidth}
            imgWidth={imgWidth}
            imgHeight={imgHeight}
            mobileBreakpoint={mobileBreakpoint}
            projectileBreakpoint={projectileBreakpoint}
          />
        </div>
        <div className="products__slideshow col-1-of-2">
          <TeeSlideshow
            dimensions={dimensions}
            slideshowWidthGt={slideshowWidthGt} 
            slideshowWidthLt={slideshowWidthLt}
            slideshowHeightGt={slideshowHeightGt}
            slideshowHeightLt={slideshowHeightLt}
            btnParentWidthGt={btnParentWidthGt}
            btnParentWidthLt={btnParentWidthLt}
            textSize={textSize}
            indicatorWidthGt={indicatorWidthGt}
            indicatorWidthLt={indicatorWidthLt}
            indicatorSpanWidth={indicatorSpanWidth}
            imgWidth={imgWidth}
            imgHeight={imgHeight}
            mobileBreakpoint={mobileBreakpoint}
            projectileBreakpoint={projectileBreakpoint}
          />
        </div>
      </div>

      {/* For Product Lines 5 & 6 */}
      <div className="products row-no u-margin-bottom-small"
        style={{
          display: dimensions.width >= breakpoint ? "flex" : "",
          justifyContent: dimensions.width >= breakpoint ? "center" : "",
          alignItems: dimensions.width >= breakpoint ? "center" : "",
        }}
      >
        <div className="products__slideshow col-1-of-2">
          <PantSlideshow
            dimensions={dimensions}
            slideshowWidthGt={slideshowWidthGt} 
            slideshowWidthLt={slideshowWidthLt}
            slideshowHeightGt={slideshowHeightGt}
            slideshowHeightLt={slideshowHeightLt}
            btnParentWidthGt={btnParentWidthGt}
            btnParentWidthLt={btnParentWidthLt}
            textSize={textSize}
            indicatorWidthGt={indicatorWidthGt}
            indicatorWidthLt={indicatorWidthLt}
            indicatorSpanWidth={indicatorSpanWidth}
            imgWidth={imgWidth}
            imgHeight={imgHeight}
            mobileBreakpoint={mobileBreakpoint}
            projectileBreakpoint={projectileBreakpoint}
          />
        </div>
        <div className="products__slideshow col-1-of-2">
          <FabricSlideshow
            dimensions={dimensions}
            slideshowWidthGt={slideshowWidthGt} 
            slideshowWidthLt={slideshowWidthLt}
            slideshowHeightGt={slideshowHeightGt}
            slideshowHeightLt={slideshowHeightLt}
            btnParentWidthGt={btnParentWidthGt}
            btnParentWidthLt={btnParentWidthLt}
            textSize={textSize}
            indicatorWidthGt={indicatorWidthGt}
            indicatorWidthLt={indicatorWidthLt}
            indicatorSpanWidth={indicatorSpanWidth}
            imgWidth={imgWidth}
            imgHeight={imgHeight}
            mobileBreakpoint={mobileBreakpoint}
            projectileBreakpoint={projectileBreakpoint}
          />
        </div>
      </div>
    </>
  );
};

export default ProductsInner;
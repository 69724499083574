const factories = [
    {
        title: "factory1",
        description: "description1",
        url: require("../../../images/factory/factory1.jpg"),
    },
    {
        title: "factory2",
        description: "description2",
        url: require("../../../images/factory/factory2.jpg"),
    },
    {
        title: "factory3",
        description: "description3",
        url: require("../../../images/factory/factory3.jpg"),
    },
    {
        title: "factory4",
        description: "description4",
        url: require("../../../images/factory/factory4.jpg"),
    },
    {
        title: "factory5",
        description: "description5",
        url: require("../../../images/factory/factory5.jpg"),
    },
    {
        title: "factory6",
        description: "description6",
        url: require("../../../images/factory/factory6.jpg"),
    },
];

export default factories;


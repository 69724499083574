import React, {useState, useEffect} from 'react';
import Nav from '../../components/Nav/Nav';
import Footer from '../../components/Footer/Footer/Footer';
import ContactInner from './ContactInner';

const Contact = ( { dimensions } ) => {

  return (
    <>
      <Nav />
      <ContactInner dimensions={dimensions} />
      <Footer />
    </>
  );
};

export default Contact;
import React, {useState, useEffect} from 'react';
import Nav from '../../components/Nav/Nav';
import Footer from '../../components/Footer/Footer/Footer';
import AboutInnerGt from './aboutInnerGt';
import AboutInnerLt from './aboutInnerLt';

const About = ( {dimensions} ) => {
  
  // Monitor resolutions
  //console.log(`dimensions.width * 0.8 * 0.5: ${dimensions.width * 0.8 * 0.5}`)
  // For window.inner.width >= 1200
  //const slideshowWidthGt = Math.floor(dimensions.width*0.435);
  //const slideshowWidthGt = Math.floor(dimensions.width * 0.555);
  const slideshowWidthGt = '514px';
  //console.log(`slideshowWidthGt: ${slideshowWidthGt}`);
  
  // For window.inner.width < 1200
  const slideshowWidthLt = Math.floor(dimensions.width * 0.762);
  //console.log(`slideshowWidthLt: ${slideshowWidthLt}`);
  const slideshowHeightGt = Math.floor(slideshowWidthGt * 1.0);
  const slideshowHeightLt = Math.floor(slideshowWidthLt * 1.4);
  
  //console.log(`slideshowWidth: ${slideshowWidth}`);
  const btnParentWidthLt = Math.floor(slideshowWidthLt * 0.12);
  const btnParentWidthGt = Math.floor(slideshowWidthGt * 0.12);
  const btnParentHeightGt = Math.floor(btnParentWidthGt * 0.5);
  const btnParentHeightLt = Math.floor(btnParentWidthLt * 0.5);
  const textSize = Math.floor(dimensions.width * 0.03);
  //const indicatorWidth = Math.floor(dimensions.width * 0.617);
  const indicatorWidthGt = Math.floor(dimensions.width * 0.617);
  const indicatorWidthLt = Math.floor(dimensions.width * 0.8);
  const indicatorSpanWidth = Math.floor(dimensions.width * 0.035);
  //console.log(`Factory Slideshow textSize: ${textSize}`);

  const imgWidth = Math.floor(dimensions.width*0.35);
  const imgHeight = Math.floor(imgWidth * 1.276);

  const breakpoint = 860;
  const mobileBreakpoint = 550;
  const projectileBreakpoint = 2200;

  // About heading Hacker effects
  const headingHacker = () => {
    // Home About Products
    const letters = "abcdefghijklmnopqrstuvwxyz0123456789";
    const headingGt = document.querySelectorAll(".aboutGt__heading--h2");
    // Convert NodeList to an array
    const headingGtArray = Array.from(headingGt);
    
    headingGtArray.map((eachHeading) => {
      eachHeading.onmouseover = event => { 
        let iterations = 0;
        const dataValue = event.target.dataset.value;
        if (!dataValue) {
          console.log("Missing data-value attribute on the element with class: 'nav' ");
          return;
        } else {
          const interval = setInterval(() => {
          event.target.innerText = event.target.innerText.split("")
          .map((letter, index) => {
          // letters[Math.floor(Math.random() * 26)]
          if (index < iterations) {
            return event.target.dataset.value[index];
          }
          return letters[Math.floor(Math.random() * 36)]
          })
          .join("");
            if(iterations >= event.target.dataset.value.length) { 
            clearInterval(interval);
          }
          iterations += 1;    
          }, 80);
          }
        }
    })

    ///
    const headingLt = document.querySelectorAll(".aboutLt__heading--h2");
    // Convert NodeList to an array
    const headingLtArray = Array.from(headingLt);
    
    headingLtArray.map((eachHeading) => {
      eachHeading.onmouseover = event => { 
        let iterations = 0;
        const dataValue = event.target.dataset.value;
        if (!dataValue) {
          console.log("Missing data-value attribute on the element with class: 'nav' ");
          return;
        } else {
          const interval = setInterval(() => {
          event.target.innerText = event.target.innerText.split("")
          .map((letter, index) => {
          // letters[Math.floor(Math.random() * 26)]
          if (index < iterations) {
            return event.target.dataset.value[index];
          }
          return letters[Math.floor(Math.random() * 36)]
          })
          .join("");
            if(iterations >= event.target.dataset.value.length) { 
            clearInterval(interval);
          }
          iterations += 1;    
          }, 80);
          }
        }
    })
  }
  /////
  const paragraphHacker = () => {
    // Home About Products
    const letters = "abcdefghijklmnopqrstuvwxyz0123456789";
    const headingGt = document.querySelectorAll(".aboutGt__inner--text-p");
    // Convert NodeList to an array
    const headingGtArray = Array.from(headingGt);
    
    headingGtArray.map((eachHeading) => {
      eachHeading.onmouseover = event => { 
        let iterations = 0;
        const dataValue = event.target.dataset.value;
        if (!dataValue) {
          console.log("Missing data-value attribute on the element with class: 'nav' ");
          return;
        } else {
          const interval = setInterval(() => {
          event.target.innerText = event.target.innerText.split("")
          .map((letter, index) => {
          // letters[Math.floor(Math.random() * 26)]
          if (index < iterations) {
            return event.target.dataset.value[index];
          }
          return letters[Math.floor(Math.random() * 36)]
          })
          .join("");
            if(iterations >= event.target.dataset.value.length) { 
            clearInterval(interval);
          }
          iterations += 1;    
          }, 0.005);
          }
        }
    })
  }

  return (
    <>
      {/* Projectile Breakpoint 1850 */}
      {/* Desktop Breakpoint 1600 */}
      {/* Tablet Breakpoint 1250 */}
      {/* Mobile Breakpoint */}

      {/* Work on < 1650 */}
      <Nav dimensions={dimensions} />
      {/* {dimensions.width >= 1200 ? (
        <AboutInnerGt1200 dimensions={dimensions} />
      ) : dimensions.width > 850 ? (
        <AboutInnerGt1200 dimensions={dimensions} />
      ) : (
        <AboutInnerLt450 dimensions={dimensions} />
      )
      } */}
      
      {dimensions.width >= breakpoint ? (
        <AboutInnerGt 
          dimensions={dimensions} 
          slideshowWidthGt={slideshowWidthGt}
          slideshowWidthLt={slideshowWidthLt}
          slideshowHeightGt={slideshowHeightGt}
          slideshowHeightLt={slideshowHeightLt}
          btnParentWidthGt={btnParentWidthGt}
          btnParentWidthLt={btnParentWidthLt}
          btnParentHeightGt={btnParentHeightGt}
          btnParentHeightLt={btnParentHeightLt}
          indicatorWidthGt={indicatorWidthGt}
          indicatorWidthLt={indicatorWidthLt}
          indicatorSpanWidth={indicatorSpanWidth}
          breakpoint={breakpoint}
          mobileBreakpoint={mobileBreakpoint}
          projectileBreakpoint={projectileBreakpoint}
          imgWidth={imgWidth}
          imgHeight={imgHeight}
          headingHacker={headingHacker}
          paragraphHacker={paragraphHacker}
        />
      ) : (
        <AboutInnerLt 
          dimensions={dimensions}
          slideshowWidthGt={slideshowWidthGt}
          slideshowWidthLt={slideshowWidthLt}
          slideshowHeightGt={slideshowHeightGt}
          slideshowHeightLt={slideshowHeightLt}
          btnParentWidthGt={btnParentWidthGt}
          btnParentWidthLt={btnParentWidthLt}
          btnParentHeightGt={btnParentHeightGt}
          btnParentHeightLt={btnParentHeightLt}
          indicatorWidthGt={indicatorWidthGt}
          indicatorWidthLt={indicatorWidthLt}
          indicatorSpanWidth={indicatorSpanWidth}
          breakpoint={breakpoint}
          mobileBreakpoint={mobileBreakpoint}
          projectileBreakpoint={projectileBreakpoint}
          imgWidth={imgWidth}
          imgHeight={imgHeight}
          headingHacker={headingHacker}
          paragraphHacker={paragraphHacker}
        />
      )
      }

      {/* {dimensions.width >= 2000 ? (
      <AboutInnerGt2000 dimensions={dimensions} />
      ) : dimensions.width >= 1600 ? (
        <AboutInnerGt1600 dimensions={dimensions} />
      ) : dimensions.width >= 1200 ? (
        <AboutInnerGt1200 dimensions={dimensions} />
      ) : dimensions.width >= 450 ? (
        <AboutInnerGt450 dimensions={dimensions} />
      ) : (
        <AboutInnerLt450 dimensions={dimensions} />
      )
      } */}
      <Footer dimensions={dimensions} />
    </>
  );
}

export default About;

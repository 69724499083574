import React from 'react';
//import aboutImage from "../../images/about.png";
import aboutImage from "../../images/about/2.jpg";
import "./aboutInner.scss";
import OfficeSlideShow from "../../components/Slideshows/Office/OfficeSlideshow";
import FactorySlideShow from "../../components/Slideshows/Factory/FactorySlideshow";

const aboutInnerLt = ( {
  dimensions, 
  slideshowWidthGt,
  slideshowWidthLt,
  slideshowHeightGt,
  slideshowHeightLt,
  btnParentWidthGt,
  btnParentWidthLt,
  btnParentHeightGt,
  btnParentHeightLt,
  indicatorSpanWidth,
  indicatorWidthGt,
  indicatorWidthLt,
  breakpoint,
  mobileBreakpoint,
  headingHacker,
  paragraphHacker,
  } ) => {

    //console.log(`slideshowWidthLt: ${slideshowWidthLt}`);
    //console.log(`slideshowHeightLt: ${slideshowHeightLt}`);
    //console.log(`dimensions.width: ${dimensions.width}`);
    const aboutImgWidth = Math.floor(dimensions.width * 0.8);
    //console.log(`aboutImgWidth: ${aboutImgWidth}`);
    //const aboutImgMarginLeft = Math.floor(dimensions.width*0.192*0.5);
    //console.log(`aboutImageMarginLeft: ${aboutImgMarginLeft}`);
    const headingSize = Math.floor(dimensions.width * 0.0475);
    //console.log(`headingSize: ${headingSize}`);
    const textSize = Math.floor(dimensions.width * 0.0375);
    //console.log(`textSize: ${textSize}`);

    // For aboutLt__intro
    const heading3Size = Math.floor(dimensions.width * 0.0475);
    const liTextSize = Math.floor(dimensions.width * 0.0375);
    const ulMarginLeft = Math.floor(dimensions.width * 0.1);
    const gridWidth = Math.floor(dimensions.width * 0.5644);
    //console.log(`gridWidth: ${gridWidth}`);

    const officeWidthLt = Math.floor(dimensions.width * 0.77);
    const officeHeightLt = Math.floor(dimensions.width * 0.287);

  return (
    <div className='about-container u-padding-top-small'>   
      <div className="aboutLt">
        <div className="aboutLt__heading">
          <h2 
            className="aboutLt__heading--h2 frosted__children"
            data-value="About Us"
            onMouseEnter={() => headingHacker()}
            style={{
              fontSize: headingSize,
            }}
          >
            <strong>About Us</strong>
          </h2>
          <p>&nbsp;</p>
        </div>

        <div className="aboutLt__inner row-no">
          <div className="aboutLt__inner--text col-1-of-2 u-margin-top-medium">
            <p               
              className="aboutGt__inner--text-p frosted__children"
              data-value="Evertex (H.K.) Limited was founded in Hong Kong, we are a professional distributor in delivering our customers with knit garments, raw materials of knit fabrics and pattern design."
              //onMouseEnter={() => paragraphHacker()}
              style={{fontSize: textSize,}}
            >
              <strong>Evertex (H.K.) Limited was founded in Hong Kong, we are a professional distributor in delivering our customers with knit garments, raw materials of knit fabrics and pattern design.</strong>
            </p>
            <p>&nbsp;</p>
            <p 
              data-value="We have experienced partners' management teams with strong sample development skills and profound experiences in monitoring the products quality of our manufacturing partners in Mainland, China."
              className="aboutGt__inner--text-p frosted__children"
              style={{fontSize: textSize,}}
            >
              <strong>We have experienced partners' management teams with strong sample development skills and profound experiences in monitoring the products quality of our manufacturing partners in Mainland, China.</strong>
            </p>
            <p>&nbsp;</p>
            <p 
              data-value="The products are exported to the United States, Australia, and many other countries and the knit facbrics are exported to all over the world."
              className="aboutGt__inner--text-p frosted__children"
              style={{fontSize: textSize,}}
            >
              <strong>The products are exported to the United States, Australia, and many other countries and the knit facbrics are exported to all over the world.</strong>
            </p>
            <p>&nbsp;</p>
                  
            {/* <p className="about__inner--cert">
            Certifications Achieved:
            </p> */}

            {/* <ul className="about__inner--ul about__inner--cert">
                  <li>STANDARD 100 by OEKO-TEX®</li>
                  <li>Workplace Conditions Assessment (WCA)</li>
                  <li>Higg Facility Module</li>
                </ul> */}

            {/* <div 
                  className="about__inner--container row"
                  style={{
                  // To over-ride margin values in _grid.scss
                  marginLeft: "2rem",
                  marginBottom: "0",
                  }}
                >
                <div className="about__inner--container--img col-1-of-3">
                  <img src={ulImage1} alt="about image1" />
                </div>
                <div className="about__inner--container--img col-1-of-3">
                  <img src={ulImage2} alt="about image2" />
                </div>
                <div className="about__inner--container--img col-1-of-3">
                  <img src={ulImage3} alt="about image3" />
                </div>
                </div> */}
          </div>
          <div className="aboutLt__inner--aboutImg col-1-of-2 frosted">
            <img 
              className="frosted__children"
              src={aboutImage} 
              alt="about_image" 
              style={{
                width: aboutImgWidth,
                //marginLeft: aboutImgMarginLeft,
                //paddingRight: "10rem",
              }}
            />
          </div>
        </div>
        
        <div className="aboutLt__intro">
        <div 
          className="aboutLt__intro--row u-margin-bottom-small frosted__children"
          style={{ width: gridWidth,}}
        >
            <h3 className="aboutLt__intro--row-h3"
                data-value="Distributions:"
                style={{
                  fontSize: heading3Size,
                }}
            >
              <strong>Distributions:</strong>
            </h3>
            <ul className="aboutLt__intro--row-ul"
              style={{
                marginLeft: ulMarginLeft,
              }}
            >
              <li className="aboutLt__intro--row-li"
                  data-value="yoga wear, active wear,"
                  style={{fontSize: liTextSize,}}
              >
                <strong>yoga wear, active wear,</strong>
              </li>
              <li className="aboutLt__intro--row-li"
                  data-value="sports wear, sports bras,"
                  style={{fontSize: liTextSize,}}
              >
                <strong>sports wear, sports bras,</strong>
              </li>
              <li className="aboutLt__intro--row-li"
                  data-value="leggings, tank tops,"
                  style={{fontSize: liTextSize,}}
              >
                <strong>leggings, tank tops,</strong>
              </li>
              
              <li className="aboutLt__intro--row-li"
                  data-value="T-shirts, shorts,"
                  style={{fontSize: liTextSize,}}
              >
                <strong>T-shirts, shorts,</strong>
              </li>
              <li className="aboutLt__intro--row-li"
                  data-value="joggers, dresses"
                  style={{fontSize: liTextSize,}}
              >
                <strong>joggers, dresses</strong>
              </li>
              <li className="aboutLt__intro--row-li"
                  data-value="with adult size range:"
                  style={{fontSize: liTextSize,}}
              >
                <strong>with adult size range:</strong>
              </li>
              <li className="aboutLt__intro--row-li"
                  data-value="XS, S, M, L, XL, XXL"
                  style={{fontSize: liTextSize,}}
              >
                <strong>XS, S, M, L, XL, XXL</strong>
              </li>
            </ul>
          </div>
          <div 
            className="aboutLt__intro--row u-margin-bottom-small frosted__children"
            style={{ width: gridWidth,}}
          >
            <h3 className="aboutLt__intro--row-h3"
                data-value="Fabric Research:"
                style={{
                  fontSize: heading3Size,
                }}
            >
              <strong>Fabric Research:</strong>
            </h3>
            <ul className="aboutLt__intro--row-ul"
              style={{
                marginLeft: ulMarginLeft,
              }}
            >
              <li className="aboutLt__intro--row-li"
                  data-value="OEM & ODM Projects"
                  style={{fontSize: liTextSize,}}
              >
                <strong>OEM & ODM Projects</strong>
              </li>
              <li className="aboutLt__intro--row-li"
                  data-value="doing pattern design"
                  style={{fontSize: liTextSize,}}
              >
                <strong>doing pattern design</strong>
              </li>
              <li className="aboutLt__intro--row-li"
                  data-value="fabric research"
                  style={{fontSize: liTextSize,}}
              >
                <strong>fabric research</strong>
              </li>
              <li className="aboutLt__intro--row-li"
                  data-value="customers' requirements"
                  style={{fontSize: liTextSize,}}
              >
                <strong>customers' requirements</strong>
              </li>
              {/* <li className="aboutLt__intro--row-li"
                  data-value="gathered from our customers"
                  style={{fontSize: liTextSize,}}
              >
                <strong>gathered from our customers</strong>
              </li> */}
            </ul>
          </div>
          <div 
            className="aboutLt__intro--row u-margin-bottom-small frosted__children"
            style={{ width: gridWidth,}}
          >
            <h3 className="aboutLt__intro--row-h3"
                data-value="Production coordination:"
                style={{
                  fontSize: heading3Size,
                }}
            >
              <strong>Production coordination:</strong>
            </h3>
            <ul className="aboutLt__intro--row-ul"
              style={{
                marginLeft: ulMarginLeft,
              }}
            >
              <li className="aboutLt__intro--row-li"
                  data-value="skilled in sampling"
                  style={{fontSize: liTextSize,}}
              >
                <strong>skilled in sampling</strong>
              </li>
              <li className="aboutLt__intro--row-li"
                  data-value="delivery on schedule"
                  style={{fontSize: liTextSize,}}
              >
                <strong>delivery on schedule</strong>
              </li>
              <li className="aboutLt__intro--row-li"
                  data-value="high quality services"
                  style={{fontSize: liTextSize,}}
              >
                <strong>high quality services</strong>
              </li>
              <li className="aboutLt__intro--row-li"
                  data-value="high quality products"
                  style={{fontSize: liTextSize,}}
              >
                <strong>high quality products</strong>
              </li>
              <li className="aboutLt__intro--row-li"
                  data-value="our customers are"
                  style={{fontSize: liTextSize,}}
              >
                <strong>our customers are</strong>
              </li>
              <li className="aboutLt__intro--row-li"
                  data-value="all over the world"
                  style={{fontSize: liTextSize,}}
              >
                <strong>all over the world</strong>
              </li>
            </ul>
          </div>
        </div>

        <div className="aboutLt__bottom">
          <div className="aboutLt__bottom--row row-no" >
            <div className="aboutLt__bottom--row-text col-1-of-2">
              <h2 
                className="aboutLt__bottom--row-text--h2 frosted__children"
                style={{
                  margin: dimensions.width < mobileBreakpoint ? "0 0" : "",
                  width: officeWidthLt,
                  height: dimensions.width < mobileBreakpoint ? "100%" : officeHeightLt
                }}
              >
                <strong>Evertex Office</strong>
              </h2>
            </div>
            <div className="aboutLt__bottom--row-slideshow col-1-of-2">
              <OfficeSlideShow 
                dimensions={dimensions}
                slideshowWidthGt={slideshowWidthGt}
                slideshowWidthLt={slideshowWidthLt}
                slideshowHeightGt={slideshowHeightGt}
                slideshowHeightLt={slideshowHeightLt}
                btnParentWidthGt={btnParentWidthGt}
                btnParentWidthLt={btnParentWidthLt}
                btnParentHeightGt={btnParentHeightGt}
                btnParentHeightLt={btnParentHeightLt}
                indicatorWidthGt={indicatorWidthGt}
                indicatorWidthLt={indicatorWidthLt}
                indicatorSpanWidth={indicatorSpanWidth}
                breakpoint={breakpoint}
                mobileBreakpoint={mobileBreakpoint}
              />
            </div>
          </div>
                    
          <div className="aboutLt__bottom--row row-no" >
            <div className="aboutLt__bottom--row-text col-1-of-2">
              <h2 
                className="aboutLt__bottom--row-text--h2 frosted__children"
                style={{
                  margin: dimensions.width < mobileBreakpoint ? "0 0" : ""
                }}
              >
                <strong>Partners' Factories</strong>
              </h2>
          </div>
          <div className="aboutLt__bottom--row-slideshow col-1-of-2 u-margin-bottom-medium">
              <FactorySlideShow 
                dimensions={dimensions}
                slideshowWidthGt={slideshowWidthGt}
                slideshowWidthLt={slideshowWidthLt}
                slideshowHeightGt={slideshowHeightGt}
                slideshowHeightLt={slideshowHeightLt}
                btnParentWidthGt={btnParentWidthGt}
                btnParentWidthLt={btnParentWidthLt}
                btnParentHeightGt={btnParentHeightGt}
                btnParentHeightLt={btnParentHeightLt}
                indicatorWidthGt={indicatorWidthGt}
                indicatorWidthLt={indicatorWidthLt}
                indicatorSpanWidth={indicatorSpanWidth}
                breakpoint={breakpoint}
                mobileBreakpoint={mobileBreakpoint}
              />
          </div>
        </div>
        </div>
      </div> 
    </div>
  );
};

export default aboutInnerLt;
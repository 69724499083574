import "./HomeInner.scss";
import React, {useState, useEffect} from "react";
import cloth1 from "../../images/home/1.jpg";
import cloth2 from "../../images/home/2.jpg";
import cloth3 from "../../images/home/3.jpg";
import cloth4 from "../../images/home/4.jpg";
import cloth5 from "../../images/home/5.jpg";
import cloth6 from "../../images/home/6.jpg";
import cloth7 from "../../images/home/7.jpg";
import cloth8 from "../../images/home/8.jpg";

const HomeInnerGt = ( {
    dimensions,
    slideshowWidthGt,
    slideshowWidthLt,
    slideshowHeightGt,
    slideshowHeightLt,
    btnParentHeightGt,
    btnParentHeightLt,
    textSize,
    indicatorWidthGt,
    indicatorWidthLt,
    indicatorSpanWidth,
    childBreakpoint,
    mobileBreakpoint,
    } ) => {

    const imgWidth = Math.floor(dimensions.width*0.415);
    //const imgWidth = Math.floor(dimensions.width*0.3);

    return (
        <div className="home-container">
            <div className="gt-container">
                <div className="gt-container__session u-margin-top-small row frosted">
                    <div className="gt-container__session--img col-1-of-2 frosted__children"
                        style={{
                            width: imgWidth
                        }}
                    >
                        <img className="gt-container__session--img" src={cloth1} />
                    </div>
                    <div className="gt-container__session--img col-1-of-2 frosted__children"
                        style={{
                            width: imgWidth
                        }}
                    >
                        <img className="gt-container__session--img" src={cloth2} />
                    </div>
                </div>

                <div className="gt-container__session row frosted">
                    <div className="gt-container__session--img col-1-of-2 frosted__children"
                        style={{
                            width: imgWidth
                        }}
                >
                        <img className="gt-container__session--img" src={cloth3} />
                    </div>
                    <div className="gt-container__session--img col-1-of-2 frosted__children"
                        style={{
                            width: imgWidth
                        }}
                    >
                        <img className="gt-container__session--img" src={cloth4} />
                    </div>
                </div>

                <div className="gt-container__session row frosted">
                    <div className="gt-container__session--img col-1-of-2 frosted__children"
                        style={{
                            width: imgWidth
                        }}
                    >
                        <img className="gt-container__session--img" src={cloth5} />
                    </div>
                    <div className="gt-container__session--img col-1-of-2 frosted__children"
                        style={{
                            width: imgWidth
                        }}
                    >
                        <img className="gt-container__session--img" src={cloth6} />
                    </div>
                </div>

                <div className="gt-container__session u-margin-bottom-small row frosted">
                    <div className="gt-container__session--img col-1-of-2 frosted__children"
                        style={{
                            width: imgWidth
                        }}
                    >
                        <img className="gt-container__session--img" src={cloth7} />
                    </div>
                    <div className="gt-container__session--img col-1-of-2 frosted__children"
                        style={{
                            width: imgWidth
                        }}
                    >
                        <img className="gt-container__session--img" src={cloth8} />
                    </div>
                </div>
            </div>
        </div>
    )
}

export default HomeInnerGt;
import React, {useState, useEffect} from 'react';
import Nav from '../../components/Nav/Nav';
import Footer from '../../components/Footer/Footer/Footer';
import HomeInner from './HomeInner';

const Home = ( {dimensions} ) => {

  // Monitor resolutions
  //console.log(`dimensions.width * 0.8 * 0.5: ${dimensions.width * 0.8 * 0.5}`)
  // For window.inner.width >= 1200
  //const slideshowWidthGt = Math.floor(dimensions.width*0.435);
  //const slideshowWidthGt = Math.floor(dimensions.width * 0.555);
  const slideshowWidthGt = '514px';
  //console.log(`slideshowWidthGt: ${slideshowWidthGt}`);
  const slideshowHeightGt = Math.floor(dimensions.width * 0.25);
  // For window.inner.width < 1200
  const slideshowWidthLt = Math.floor(dimensions.width * 0.879);
  //console.log(`slideshowWidthLt: ${slideshowWidthLt}`);

  const slideshowHeightLt = Math.floor(slideshowWidthLt * 2.2);
  //console.log(`slideshowWidth: ${slideshowWidth}`);
  const btnParentWidthLt = Math.floor(slideshowWidthLt * 0.12);
  const btnParentWidthGt = Math.floor(slideshowWidthGt * 0.12);
  const btnParentHeightGt = Math.floor(btnParentWidthGt * 0.5);
  const btnParentHeightLt = Math.floor(btnParentWidthLt * 0.5);
  const textSize = Math.floor(dimensions.width * 0.03);
  //const indicatorWidth = Math.floor(dimensions.width * 0.617);
  const indicatorWidthGt = Math.floor(dimensions.width * 0.617);
  const indicatorWidthLt = Math.floor(dimensions.width * 0.8);
  const indicatorSpanWidth = Math.floor(dimensions.width * 0.035);
  //console.log(`Factory Slideshow textSize: ${textSize}`);
  const childBreakpoint = 920;

  return (
    <>
      <Nav 
        dimensions={dimensions}
        slideshowWidthGt={slideshowWidthGt} 
        slideshowWidthLt={slideshowWidthLt}
        slideshowHeightGt={slideshowHeightGt}
        slideshowHeightLt={slideshowHeightLt}
        btnParentHeightGt={btnParentHeightGt}
        btnParentHeightLt={btnParentHeightLt}
        textSize={textSize}
        indicatorWidthGt={indicatorWidthGt}
        indicatorWidthLt={indicatorWidthLt}
        indicatorSpanWidth={indicatorSpanWidth}
        childBreakpoint={childBreakpoint}
      />
      <HomeInner 
        dimensions={dimensions} 
        slideshowWidthGt={slideshowWidthGt} 
        slideshowWidthLt={slideshowWidthLt}
        slideshowHeightGt={slideshowHeightGt}
        slideshowHeightLt={slideshowHeightLt}
        btnParentHeightGt={btnParentHeightGt}
        btnParentHeightLt={btnParentHeightLt}
        textSize={textSize}
        indicatorWidthGt={indicatorWidthGt}
        indicatorWidthLt={indicatorWidthLt}
        indicatorSpanWidth={indicatorSpanWidth}
        childBreakpoint={childBreakpoint}
      />
      <Footer 
        dimensions={dimensions}
        slideshowWidthGt={slideshowWidthGt} 
        slideshowWidthLt={slideshowWidthLt}
        slideshowHeightGt={slideshowHeightGt}
        slideshowHeightLt={slideshowHeightLt}
        btnParentHeightGt={btnParentHeightGt}
        btnParentHeightLt={btnParentHeightLt}
        textSize={textSize}
        indicatorWidthGt={indicatorWidthGt}
        indicatorWidthLt={indicatorWidthLt}
        indicatorSpanWidth={indicatorSpanWidth}
        childBreakpoint={childBreakpoint} 
      />
    </>
  );
};

export default Home;

import React, {useState, useEffect} from 'react';
import './ContactInner.scss';

const contactInner = ( { dimensions } ) => {

  const frostedWidth = Math.floor(dimensions.width * 0.58);
  const gridWidth = Math.floor(dimensions.width * 0.5);
  const borderRadius = Math.floor(dimensions.width * 0.0315);
  const headingSize = Math.floor(dimensions.width * 0.035);
  
  //console.log(`frostedWidth: ${frostedWidth}`);
  //console.log(`gridWidth: ${gridWidth}`);

  // Contact Us heading Hacker effects
  const headingHacker = () => {
    // Home About Products
    const letters = "abcdefghijklmnopqrstuvwxyz0123456789";
    const heading = document.querySelectorAll(".contact__h2");
    // Convert NodeList to an array
    const headingArray = Array.from(heading);
    
    headingArray.map((eachHeading) => {
      eachHeading.onmouseover = event => { 
        let iterations = 0;
        const dataValue = event.target.dataset.value;
        if (!dataValue) {
          console.log("Missing data-value attribute on the element with class: 'nav' ");
          return;
        } else {
          const interval = setInterval(() => {
          event.target.innerText = event.target.innerText.split("")
          .map((letter, index) => {
          // letters[Math.floor(Math.random() * 26)]
          if (index < iterations) {
            return event.target.dataset.value[index];
          }
          return letters[Math.floor(Math.random() * 36)]
          })
          .join("");
            if(iterations >= event.target.dataset.value.length) { 
            clearInterval(interval);
          }
          iterations += 1;    
          }, 80);
          }
        }
    })
  }

  return (
    <>
      <div className="contact frosted u-margin-bottom-small">
        <h2 className="contact__h2"
          data-value="Contact Us"
          onMouseEnter={() => headingHacker() }
          style={{
            fontSize: headingSize,
          }}
        >
          <strong>Contact Us</strong>
        </h2>
      </div>
      <div className="contact u-margin-bottom-medium frosted__children"
        style={{
          borderRadius: borderRadius,
          width: frostedWidth,
          height: frostedWidth,
        }}
      >
        <iframe 
          className="contact__googleMap"
          style={{
            borderRadius: borderRadius,
            width: gridWidth,
            height: gridWidth,
          }}
          src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3690.410271578698!2d114.1470473!3d22.338132400000003!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3404075453057c4d%3A0xd92b6d469f1c0239!2sTrendy%20Centre!5e0!3m2!1sen!2shk!4v1703528247043!5m2!1sen!2shk" 
          width="600" 
          height="450" 
          //style="border:0;" 
          allowfullscreen="" 
          loading="lazy" 
          referrerpolicy="no-referrer-when-downgrade"
        />
      </div>
    </>
  );
};

export default contactInner;